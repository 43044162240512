var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"reserva-web-table"}},[_c('v-dialog',{attrs:{"id":"modal-reservas","content-class":"modal-form","fullscreen":"","hide-overlay":"","scrollable":"","eager":"","persistent":"","no-click-animation":"","transition":"dialog-bottom-transition"},model:{value:(_vm.openViewModel),callback:function ($$v) {_vm.openViewModel=$$v},expression:"openViewModel"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"matchpal","max-height":"70px"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.openViewModel = false}}},[_c('v-icon',[_vm._v("close")])],1),_c('v-toolbar-title',[_vm._v(" Reservas Web ")])],1),_c('v-card-text',{staticClass:"pa-1 ma-0"},[_c('v-text-field',{attrs:{"append-icon":"search","label":"Filtrar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.events,"footer-props":{ 'items-per-page-options': [20,50,100,-1] },"items-per-page":20,"loading":_vm.loading,"search":_vm.search,"item-key":"id"},scopedSlots:_vm._u([{key:"item.quadra",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"150px"}},[_vm._v(_vm._s(item.quadra))])]}},{key:"item.tipo",fn:function(ref){
var item = ref.item;
return [(item.tipo == 'web_pendente')?[_c('div',{staticClass:"text-no-wrap pa-0 ma-0"},[_c('v-btn',{attrs:{"text":"","color":"green","disabled":_vm.estabelecimento.bloqueio != null},on:{"click":function($event){return _vm.acaoNotificacao(item, 'confirmar')}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("check_circle")]),_vm._v("Aceitar")],1),_c('v-btn',{attrs:{"text":"","color":"red","disabled":_vm.estabelecimento.bloqueio != null},on:{"click":function($event){return _vm.acaoNotificacao(item, 'rejeitar')}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("cancel")]),_vm._v("Rejeitar")],1)],1),(_vm.estabelecimento.bloqueio != null)?_c('WhatsLink',{attrs:{"text":("O estabelecimento " + (_vm.estabelecimento.nome) + " está bloqueado. Gostaria de obter informações para regularizar a situação."),"phone":"5555996794845"}},[_c('v-alert',{staticClass:"mb-5",attrs:{"border":"left","dense":"","outlined":"","text":"","color":"red"}},[_vm._v(" Estabelecimento bloqueado. Entre em contato com o suporte clicando aqui. ")])],1):_vm._e()]:_vm._e(),(item.tipo == 'web_confirmada')?_c('v-icon',[_vm._v("check_circle_outline")]):_vm._e(),(item.tipo == 'web_rejeitada')?_c('v-icon',[_vm._v("highlight_off")]):_vm._e(),(item.tipo == 'web_vencida')?_c('v-icon',[_vm._v("hourglass_empty")]):_vm._e(),(item.tipo == 'web_paga')?_c('v-icon',[_vm._v("attach_money")]):_vm._e(),(item.tipo == 'web_pix_pendente')?_c('v-icon',{staticClass:"pix-icon-pending"},[_vm._v("fa-brands fa-pix")]):_vm._e(),(item.tipo == 'web_pix_vencido')?_c('v-icon',[_vm._v("fa-brands fa-pix")]):_vm._e(),(item.tipo == 'web_pix_pago')?_c('v-icon',{staticClass:"pix-icon-paid"},[_vm._v("fa-brands fa-pix")]):_vm._e(),(item.excluida)?_c('v-icon',[_vm._v("delete_outline")]):_vm._e()]}},{key:"item.celular",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","transition":"fab-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.formatedCel = _vm.formatCel(item.celular), _vm.formatedText = _vm.formatText(item), _vm.fetchNotifications()}}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.celular)+" ")])]}}],null,true)},[_c('v-list',[(_vm.isMobile)?_c('v-list-item',[_c('a',{attrs:{"href":("tel:" + (item.celular))}},[_vm._v("Ligar")])]):_vm._e(),(item.tipo != 'web_pendente')?_c('v-list-item',[_c('WhatsLink',{attrs:{"phone":_vm.formatedCel,"text":_vm.formatedText,"icon":"before"}},[_vm._v(" Avisar ")])],1):_vm._e(),_c('v-list-item',[_c('WhatsLink',{attrs:{"phone":_vm.formatedCel,"text":'',"icon":"before"}},[_vm._v(" Conversar ")])],1)],1)],1)]}},{key:"item.codigo",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("https://zumer.com.br/reserva/" + (item.codigo)),"target":"_blank"}},[_vm._v(_vm._s(item.codigo))])]}}])})],1)],1)],1),_c('Load',{attrs:{"load":_vm.load}}),_c('Snackbar',{attrs:{"snackbar-flag":_vm.snackbar,"permanent":_vm.permanetSnackbar,"error":_vm.errorSnackbar},scopedSlots:_vm._u([{key:"message",fn:function(){return [_vm._v(_vm._s(_vm.textSnackbar))]},proxy:true},(_vm.phoneSnackbar)?{key:"actions",fn:function(){return [_c('WhatsLink',{staticClass:"v-btn v-btn--flat v-btn--text v-size--default white--text",attrs:{"phone":_vm.phoneSnackbar,"text":_vm.textWhatsSnackbar,"icon":"after"}},[_vm._v(" Avisar ")])]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }