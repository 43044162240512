<template>
    <v-app id="reserva-web-table">
        <v-dialog id="modal-reservas" v-model="openViewModel"
        content-class="modal-form" fullscreen
        hide-overlay scrollable eager persistent no-click-animation
        transition="dialog-bottom-transition">

            <v-card>
                <v-toolbar flat dark color="matchpal" max-height="70px">
                    <v-btn icon dark @click="openViewModel = false">
                        <v-icon>close</v-icon>
                    </v-btn>

                    <v-toolbar-title>
                        Reservas Web
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pa-1 ma-0">
                    <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Filtrar"
                    single-line
                    hide-details
                    />

                    <v-data-table
                        dense
                        :headers="headers"
                        :items="events"
                        :footer-props="{ 'items-per-page-options': [20,50,100,-1] }"
                        :items-per-page="20"
                        :loading="loading"
                        :search="search"
                        item-key="id"
                        class="elevation-1"
                        >
                        <template v-slot:item.quadra="{ item }">
                            <span class="d-inline-block text-truncate" style="max-width: 150px;">{{ item.quadra }}</span>
                        </template>
                        <template v-slot:item.tipo="{ item }">
                            <template v-if="item.tipo == 'web_pendente'">
                                <div class="text-no-wrap pa-0 ma-0">
                                    <v-btn text color="green" :disabled="estabelecimento.bloqueio != null" @click="acaoNotificacao(item, 'confirmar')"><v-icon color="green">check_circle</v-icon>Aceitar</v-btn>
                                    <v-btn text color="red" :disabled="estabelecimento.bloqueio != null" @click="acaoNotificacao(item, 'rejeitar')"><v-icon color="red">cancel</v-icon>Rejeitar</v-btn>
                                </div>
                                <WhatsLink v-if="estabelecimento.bloqueio != null"
                                            :text="`O estabelecimento ${estabelecimento.nome} está bloqueado. Gostaria de obter informações para regularizar a situação.`"
                                            phone="5555996794845">
                                    <v-alert class="mb-5" border="left" dense outlined text color="red">
                                        Estabelecimento bloqueado. Entre em contato com o suporte clicando aqui.
                                    </v-alert>
                                </WhatsLink>
                            </template>
                            <v-icon v-if="item.tipo == 'web_confirmada'">check_circle_outline</v-icon>
                            <v-icon v-if="item.tipo == 'web_rejeitada'">highlight_off</v-icon>
                            <v-icon v-if="item.tipo == 'web_vencida'">hourglass_empty</v-icon>
                            <v-icon v-if="item.tipo == 'web_paga'">attach_money</v-icon>
                            <v-icon v-if="item.tipo == 'web_pix_pendente'" class="pix-icon-pending">fa-brands fa-pix</v-icon>
                            <v-icon v-if="item.tipo == 'web_pix_vencido'">fa-brands fa-pix</v-icon>
                            <v-icon v-if="item.tipo == 'web_pix_pago'" class="pix-icon-paid">fa-brands fa-pix</v-icon>
                            <v-icon v-if="item.excluida">delete_outline</v-icon>
                        </template>
                        <template v-slot:item.celular="{ item }">
                            <v-menu offset-y transition="fab-transition">
                                <template v-slot:activator="{ on, attrs }">
                                    <a
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="formatedCel = formatCel(item.celular), formatedText = formatText(item), fetchNotifications()"
                                    >
                                    {{ item.celular }}
                                    </a>
                                </template>
                                <v-list>
                                    <v-list-item v-if="isMobile">
                                        <a :href="`tel:${item.celular}`">Ligar</a>
                                    </v-list-item>
                                    <v-list-item v-if="item.tipo != 'web_pendente'">
                                        <WhatsLink
                                            :phone="formatedCel" :text="formatedText" icon="before">
                                            Avisar
                                        </WhatsLink>
                                    </v-list-item>
                                    <v-list-item>
                                        <WhatsLink
                                            :phone="formatedCel" :text="''" icon="before">
                                            Conversar
                                        </WhatsLink>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                        <template v-slot:item.codigo="{ item }">
                            <a :href="`https://zumer.com.br/reserva/${item.codigo}`" target="_blank">{{ item.codigo }}</a>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <Load :load="load" />
        <Snackbar :snackbar-flag="snackbar" :permanent="permanetSnackbar" :error="errorSnackbar">
            <template v-slot:message>{{ textSnackbar }}</template>
            <template v-if="phoneSnackbar" v-slot:actions>
                <WhatsLink class="v-btn v-btn--flat v-btn--text v-size--default white--text"
                    :phone="phoneSnackbar" :text="textWhatsSnackbar" icon="after">
                    Avisar
                </WhatsLink>
            </template>
        </Snackbar>
    </v-app>
</template>

<script>
import Utils from "@components/Utils/Utils.vue"
import detectMobileBrowser from '@plugins/detectmobilebrowser.js'
import delay from 'lodash/delay'
import moment from 'moment'
import Mensagens from '@components/Mensagens.vue'

export default {
    name: 'ReservasWeb',
    components: {
        WhatsLink: () => import('@components/WhatsappLink.vue'),
        Snackbar: () => import('@components/Snackbar.vue'),
        Load: () => import('@components/Load.vue'),
    },
    mixins: [ Utils, Mensagens ],
    props: {
        reservasFlag: {
            type: Number,
            default: 1,
        },
        estabelecimento: {
            type: Object,
            default: function () {
                return new Object()
            }
        },
    },
    data: () => ({
        openViewModel: false,
        countUnread: '',
        eventBackgroundRefresh: null,
        loading: true,
        search: '',
        formatedCel: '',
        formatedText: '',
        headers: [
            { text: 'Estado', value: 'tipo', sortable: false },
            { text: 'Quadra', value: 'quadra', sortable: false },
            { text: 'Dia', value: 'dia', sortable: false },
            { text: 'Data reservada', width: '115px', value: 'dataJogo', sortable: false },
            { text: 'Duração [min]', value: 'duracao', sortable: false },
            { text: 'Nome jogador', value: 'jogador', sortable: false },
            { text: 'Celular', value: 'celular', sortable: false },
            { text: 'Esporte', value: 'esporte', sortable: false },
            { text: 'Observação', value: 'obs', sortable: false },
            { text: 'Ocorrência', align: 'start', value: 'ocorrencia', sortable: false },
            { text: 'Código solicitação', value: 'codigo', sortable: false },
            { text: 'Código sistema', value: 'id', sortable: false },
        ],
        events: [],
        isMobile: detectMobileBrowser(),
        snackbar: 0,
        textSnackbar: '',
        phoneSnackbar: '',
        errorSnackbar: false,
        textWhatsSnackbar: '',
        permanetSnackbar: true,
        load: false,
    }),
    computed: {
    },
    watch: {
        reservasFlag: function() {
            this.openViewModel = true
            this.forceFetch()
        },
    },
    mounted() {
        try{
            delay( this.forceFetch, 5000 )
        } catch(e){
            history.go(0)
        }
        window.addEventListener('popstate', this.popstate)
    },
    destroyed() {
        clearTimeout(this.eventBackgroundRefresh)
        window.removeEventListener('popstate', this.popstate, false)
    },

    methods: {
        popstate(){
            this.openViewModel = false
        },
        formatCel: function(cel){
            const onlyDigits = cel.replace(/\D/g, '')
            return '55' + onlyDigits
        },
        formatText: function(notif){
            const reservaInfo = {
                nome: notif.jogador,
                quadra: notif.quadra,
                data: moment(notif.dataJogo.substring(0,8), 'DD-MM-YY').format('DD/MM/YYYY'),
                dia_semana: moment(notif.dataJogo.substring(0,8), 'DD-MM-YY').format('dddd'),
                hora: notif.dataJogo.substring(9,14),
                duracao: this.stringDuracao(notif.duracao),
                preco: notif.preco,
                obs: notif.obs,
                tipo: 'avulso',
                codigo_web: notif.codigo,
            }

            if (notif.tipo === "web_rejeitada")
                return this.getWhatsMsg('web_recusada', reservaInfo)
            else if(!!notif.excluida)
                return this.getWhatsMsg('avulso_desmarcado', reservaInfo)
            else if(notif.tipo === "web_pix_pendente")
                return this.getWhatsMsg('web_pix_pendente', reservaInfo)
            else if(notif.tipo === "web_pix_vencido")
                return this.getWhatsMsg('web_pix_vencido', reservaInfo)
            else if(notif.tipo === "web_confirmada" || notif.tipo === "web_paga" || notif.tipo === "web_pix_pago")
                return this.getWhatsMsg('web_confirmada', reservaInfo)
            // Não existe mensagem para web_vencida
        },
        fetchEvents: function () {
            this.loading = true
            this.$http({
                method: 'post',
                url: '/apiweb/reservasweb',
                data: {
                    days: this.periodo,
                }
            }).then(response => {
                if (response.data.success == true) {
                    this.events = response.data.historico
                }
                this.loading = false
            })
        },
        fetchNotifications: function(){
            this.$http({
                method: 'post',
                url: '/apiweb/notificacoes',
                data: {},
            }).then(response => {
                this.countUnread = response.data.unread
                if (this.openViewModel) {
                    this.fetchEvents()
                }
                this.$emit('requestNotification', this.countUnread)
            }).catch(error => {
                console.log('Erro fetch notificações ', error)
                this.textSnackbar = 'Erro ao buscar as notificações!'
                this.phoneSnackbar = ''
                this.errorSnackbar = true
                this.permanetSnackbar = false
                this.snackbar++
            })
        },
        forceFetch: function() {
            clearTimeout(this.eventBackgroundRefresh)
            this.fetchNotifications()
            this.eventBackgroundRefresh = setInterval(this.fetchNotifications, 2 * 60 * 1000) // 2 minutos
        },
        acaoNotificacao: function(item, acao){
            this.load = true
            let url = '/reservaweb/'+acao
            let data = {reserva_id: item.id}
            this.$http({
                method: 'post',
                url: url,
                data: data
            }).then(response => {
                if (response.data.mensagem == 'ok') {
                    let status = ''
                    if (acao == 'confirmar'){
                        status = 'confirmada'
                        item.tipo = 'web_confirmada'
                        this.$emit('refreshHorarios')
                    } else {
                        status = 'rejeitada'
                        item.tipo = 'web_rejeitada'
                    }
                    this.textSnackbar = `Solicitação ${ status } com sucesso!`
                    this.phoneSnackbar = this.formatCel(item.celular)
                    this.textWhatsSnackbar = this.formatText(item)
                    this.errorSnackbar = false
                } else if (response.data.mensagem == 'solicitacao ja respondida') {
                    this.textSnackbar = 'Esta solicitação já foi anteriormente respondida!'
                    this.phoneSnackbar = ''
                    this.errorSnackbar = true
                } else if (response.data.mensagem == 'solicitacao vencida') {
                    this.textSnackbar = "Esta solicitação de reserva já está vencida!"
                    this.phoneSnackbar = ''
                    this.errorSnackbar = true
                } else if (response.data.mensagem == 'horario_ocupado') {
                    this.textSnackbar = "O horário solicitado já está ocupado!"
                    this.phoneSnackbar = ''
                    this.errorSnackbar = true
                } else {
                    this.textSnackbar = "Falha ao efetuar a ação! Tente novamente."
                    this.phoneSnackbar = ''
                    this.errorSnackbar = true
                }
                this.permanetSnackbar = true
                this.snackbar++
                this.load = false
                this.forceFetch()
            }).catch(error => {
                this.load = false
                this.textSnackbar = 'Falha ao efetuar a ação! Tente novamente.'
                this.phoneSnackbar = ''
                this.permanetSnackbar = false
                this.snackbar++
                this.forceFetch()
            })
        },
    }
}
</script>

<style lang="sass" scoped>
    @import '@assets/css/global-vars.sass'
    .pix-icon-pending
        color: $orange
    .pix-icon-paid
        color: darkseagreen
</style>